import { useQuery } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { unwrapDoc } from '../../utils';
import { getDoc } from 'firebase/firestore';

const fetchMentalityAssessment = async (assessmentID) => {
  const assessmentDoc = await getDoc(AssessmentRef(assessmentID));

  if (!assessmentDoc.exists()) {
    return null;
  }

  const assessment = unwrapDoc(assessmentDoc);

  return assessment;
};

export default function useMentalityAssessment(assessmentID) {
  return useQuery({
    queryKey: ['mentalityAssessment', assessmentID],
    queryFn: async () => fetchMentalityAssessment(assessmentID),
    enabled: !!assessmentID
  });
}
