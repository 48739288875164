import { doc, collection } from 'firebase/firestore';
import { db } from '../utils';

export function AssessmentVersionsRef(assessmentVersion) {
  return doc(db, 'assessmentVersions', assessmentVersion);
}

export function AssessmentRef(assessmentID) {
  return doc(db, 'assessments', assessmentID);
}

export function AssessmentActivityLogsRef(assessmentID) {
  return doc(db, 'logs/assessmentActivity/logs', assessmentID);
}
