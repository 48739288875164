import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
// import Toast from '../../toast';
import { ArrowRight, ArrowLeft, SquareCheck } from 'lucide-react';

function ChooseManyQuestion({ response, question, handleUpdate, handleDelete, currentQuestionIndex, handleNextQuestion, isLastQuestion, submitAssessment }) {
  // choose top 3 choices then choose bottom 1 choice
  const { id, text, options, displayIndex } = question;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLeastOption, setSelectedLeastOption] = useState(null);
  const [showFirstQuestionPart, setShowFirstQuestionPart] = useState(true);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showFirstQuestionPart]);

  useEffect(() => {
    setSelectedOptions(response?.best || []);
    setSelectedLeastOption(response?.least || null);
    // if best options are selected, show the second part of the question
    // only run when page loads
    // if (response?.best && response?.best.length === 3) {
    //   setShowFirstQuestionPart(false);
    // }
  }, [response]);

  const handleBestOptions = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
    } else if (selectedOptions.length < 3) {
      setSelectedOptions([...selectedOptions, option]);
    }

    // show toast if user tries to select more than 3 options
    if (selectedOptions.length === 3 && !selectedOptions.includes(option)) {
      setShowToast(true);
    }
  };

  const submitBestOptions = () => {
    const formattedResponse = {
      best: selectedOptions
    };
    handleUpdate(id, formattedResponse);
  };

  const handleLeastOption = (option) => {
    if (selectedLeastOption === option) {
      setSelectedLeastOption(null);
    } else if (!selectedLeastOption) {
      setSelectedLeastOption(option);
    }
    // show toast if user tries to select more than 1 worst option
    if (selectedLeastOption && selectedLeastOption !== option) {
      setShowToast(true);
    }
  };

  const submitLeastOption = () => {
    const formattedResponse = {
      least: selectedLeastOption
    };
    handleUpdate(id, formattedResponse);
  };

  const clearOptionsAndView = () => {
    if (!isLastQuestion) {
      setSelectedOptions([]);
      setSelectedLeastOption(null);
      setShowFirstQuestionPart(true);
    }
  };

  const sortedOptions = options.sort((a, b) => a.id - b.id);

  // render the first part of the question (choose top 3 options)
  // then render the second part of the question (choose bottom 1 option) with the top 3 options disabled
  // show the best selected options in a 1, 2, 3 order

  const renderButtons = () => {
    return (
      <div className="button-container choose-many">
        <button
          className="button grey-outline move-button"
          onClick={() => {
            if (showFirstQuestionPart) {
              handleNextQuestion(currentQuestionIndex - 1);
              setShowFirstQuestionPart(false);
            } else {
              setShowFirstQuestionPart(true);
            }
          }}>
          <ArrowLeft />
        </button>
        <div className="divider"></div>
        <button
          disabled={showFirstQuestionPart ? selectedOptions.length < 3 : !selectedLeastOption}
          className="button primary-button move-button"
          onClick={() => {
            if (showFirstQuestionPart) {
              setShowFirstQuestionPart(false);
              submitBestOptions();
            } else {
              submitLeastOption();
              clearOptionsAndView();
              if (!isLastQuestion) {
                handleNextQuestion(currentQuestionIndex + 1);
              } else {
                submitAssessment();
              }
            }
          }}>
          {isLastQuestion && !showFirstQuestionPart ? 'Submit' : <ArrowRight />}
        </button>
      </div>
    );
  };

  return (
    <div className="assessment-question-container">
      <div className="question-container">
        <div className="question-number">{displayIndex}.</div>
        <div className="question-text" dangerouslySetInnerHTML={{ __html: showFirstQuestionPart ? text : 'From the list below, please pick one statement that you feel is LEAST like you.' }}></div>
      </div>
      {showFirstQuestionPart && (
        <div className="question-options-container choose-many">
          {sortedOptions.map((option, index) => (
            <button
              key={index}
              className={`button question-button choose-many best-three-options ${selectedOptions.includes(option.id) ? 'selected' : ''}`}
              onClick={(e) => {
                handleBestOptions(option.id);
              }}>
              <div>{option.label}</div>
              {selectedOptions.includes(option.id) && (
                <div className="check-mark">
                  <SquareCheck size={18} />
                </div>
              )}
            </button>
          ))}
          {renderButtons()}
        </div>
      )}
      {!showFirstQuestionPart && (
        <div className="question-options-container choose-many">
          {sortedOptions
            .filter((option) => !selectedOptions.includes(option.id))
            .map((option, index) => (
              <button
                key={index}
                disabled={selectedOptions.includes(option.label)}
                className={`button question-button choose-many least-option ${selectedLeastOption === option.id ? 'selected' : ''}`}
                onClick={(e) => {
                  handleLeastOption(option.id);
                }}>
                {option.label}
              </button>
            ))}
          {renderButtons()}
        </div>
      )}
      {/* <CSSTransition in={showToast} timeout={2000} classNames="toast" unmountOnExit> */}
      {/* {showToast && <Toast text="Deselect an option before selecting a different choice" type="info" onDismiss={setShowToast} />} </CSSTransition> */}
    </div>
  );
}

export default ChooseManyQuestion;
