import React, { useEffect, useState } from 'react';
import Statement from './statement';
import RatingQuestion from './ratingQuestion';
import ChoiceQuestion from './choiceQuestion';
import ChooseManyQuestion from './chooseManyQuestion';
import ProgressBar from '../../progressBar';
import { useUpdateMentalityAssessment, useCompleteMentalityAssessment, useRemoveMentalityAssessmentResponse, useBulkUpdateMentalityAssessment, useUpdateAssessmentActivityLog } from '../../../hooks';
import _ from 'lodash';
import { isProduction, signOut } from '../../../utils';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import Error from '../../util/error';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MOCK_RESPONSES from '../../../data/mockResponsesData';

const CHOICE_QUESTION_TYPES = ['likert', 'choice', 'boolean', 'ranking'];
const RATING_QUESTION_TYPES = ['rating-5', 'rating-10'];
const CHOOSE_MANY_QUESTION_TYPES = ['choose-many'];
const STATEMENT_QUESTIONS = ['statement'];

const submitAssessmentTimeDelay = 4000;

const getFirstUnansweredQuestionIndex = (responses, questions) => {
  if (_.isEmpty(responses)) {
    return 0;
  }
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].questionType === 'choose-many') {
      const bestResponses = responses[questions[i].id]?.best;
      const leastResponse = responses[questions[i].id]?.least;
      if (!bestResponses || bestResponses.length < 3 || !leastResponse) {
        return i;
      }
    } else if (questions[i].questionType !== 'statement' && !responses[questions[i].id]) {
      return i;
    }
  }

  return questions.length - 1;
};

//replace * with bold tags
const formatQuestionWithHTMLTags = (text) => {
  return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
};

function AssessmentQuestions({ mentalityAssessment, orderedMentalityAssessmentQuestions, scorabilityUserId, redirectURL }) {
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(getFirstUnansweredQuestionIndex(mentalityAssessment?.responses, orderedMentalityAssessmentQuestions));
  const [response, setResponse] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);
  const { mutate: updateMentalityAssessment } = useUpdateMentalityAssessment(scorabilityUserId);
  const { mutate: completeMentalityAssessment } = useCompleteMentalityAssessment(scorabilityUserId);
  const { mutate: removeMentalityAssessmentResponse } = useRemoveMentalityAssessmentResponse(scorabilityUserId);
  const { mutate: bulkUpdateMentalityAssessment } = useBulkUpdateMentalityAssessment(scorabilityUserId);
  const { mutate: updateAssessmentActivityLog } = useUpdateAssessmentActivityLog(scorabilityUserId);

  const scorabilityRedirectURL = redirectURL
    ? redirectURL
    : isProduction()
      ? `https://dashboard.scorability.com/athlete/${scorabilityUserId}/mentality/confirm-complete`
      : `https://dashboard.scorability.org/athlete/${scorabilityUserId}/mentality/confirm-complete`;

  useEffect(() => {
    window.scrollTo(0, 0);
    // if the question is not a statement and they are not at the end of the assessment, update assessmentActivityLog
    if (
      orderedMentalityAssessmentQuestions[currentQuestionIndex].questionType !== 'statement' &&
      mentalityAssessment.status !== 'completed' &&
      mentalityAssessment.status !== 'scored' &&
      mentalityAssessment.status !== 'scoring'
    ) {
      updateAssessmentActivityLog({ questionID: orderedMentalityAssessmentQuestions[currentQuestionIndex].id, type: 'questionViewed' });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (orderedMentalityAssessmentQuestions[currentQuestionIndex]) {
      setResponse(mentalityAssessment.responses[orderedMentalityAssessmentQuestions[currentQuestionIndex]?.id]);
    }
  }, [currentQuestionIndex, mentalityAssessment.responses]);

  const notify = () => toast.info('Submitting Assessment...');

  const handleStatementAction = (progress, prefill = false) => {
    if (prefill) {
      // prefill the responses
      const responses = MOCK_RESPONSES;
      bulkUpdateMentalityAssessment({ changes: responses });
      setCurrentQuestionIndex(orderedMentalityAssessmentQuestions.length - 1);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + progress);
    }
  };

  const handleQuestionResponse = (questionId, response) => {
    updateMentalityAssessment({ questionID: questionId, changes: response });
    updateAssessmentActivityLog({ questionID: questionId, type: 'responseSubmitted' });
    if (currentQuestionIndex !== orderedMentalityAssessmentQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    setDisableButtons(false);
  };

  const handleRemoveQuestionResponse = (questionId) => {
    removeMentalityAssessmentResponse({ questionID: questionId });
  };

  const handleChooseManyQuestionResponse = (questionId, response) => {
    updateMentalityAssessment({ questionID: questionId, changes: response });
  };

  const submitAssessment = () => {
    setDisableButtons(true);
    updateAssessmentActivityLog({ questionID: null, type: 'assessmentSubmitted' });
    completeMentalityAssessment();

    setTimeout(() => {
      // sign out user
      signOut();
      setDisableButtons(false);
      // redirect to scorability.com page with history push
      window.location.href = scorabilityRedirectURL;
    }, submitAssessmentTimeDelay);
  };

  if (mentalityAssessment.status === 'completed' || mentalityAssessment.status === 'scored' || mentalityAssessment.status === 'scoring') {
    // thank you for completing the assessment with button to click to go to dashboard of scorabilty. when first completing it is fine to redirect after 5 seconds
    return <Error message="Thank you for completing the assessment." buttonUrl={scorabilityRedirectURL} scorabilityUserId={scorabilityUserId} isCompleted={true} />;
  }

  const currentQuestion = orderedMentalityAssessmentQuestions[currentQuestionIndex];
  currentQuestion.text = formatQuestionWithHTMLTags(currentQuestion.text);
  const currentProgress = (currentQuestionIndex / orderedMentalityAssessmentQuestions.length) * 100;

  const renderButtons = () => {
    if (currentQuestion.questionType === 'choose-many') {
      return null;
    }

    return (
      <div className="button-container">
        {currentQuestionIndex > 0 && !STATEMENT_QUESTIONS.includes(currentQuestion.questionType) && (
          <button
            className="button grey-outline move-button"
            disabled={disableButtons}
            onClick={() => {
              setCurrentQuestionIndex(currentQuestionIndex - 1);
            }}>
            <ArrowLeft />
          </button>
        )}
        {response && currentQuestionIndex !== orderedMentalityAssessmentQuestions.length - 1 && (
          <>
            <div className="divider"></div>
            <button className="button primary-button move-button" disabled={disableButtons} onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}>
              <ArrowRight />
            </button>
          </>
        )}

        {currentQuestionIndex === orderedMentalityAssessmentQuestions.length - 1 && (
          <button
            className="button primary-button move-button"
            disabled={!response || disableButtons}
            onClick={() => {
              notify();
              submitAssessment();
            }}>
            Submit
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      {
        <>
          <ProgressBar progress={response && currentQuestionIndex === orderedMentalityAssessmentQuestions.length - 1 ? 100 : currentProgress} />
          {STATEMENT_QUESTIONS.includes(currentQuestion.questionType) && (
            <Statement text={currentQuestion.text} subtext={currentQuestion.subtext || ''} showBackButton={currentQuestionIndex > 0} handleAction={handleStatementAction} />
          )}
          {CHOICE_QUESTION_TYPES.includes(currentQuestion.questionType) && (
            <ChoiceQuestion
              response={response}
              question={currentQuestion}
              handleUpdate={handleQuestionResponse}
              handleDelete={handleRemoveQuestionResponse}
              isButtonsDisabled={disableButtons}
              disableButtons={setDisableButtons}
              isLastQuestion={currentQuestionIndex === orderedMentalityAssessmentQuestions.length - 1}
            />
          )}
          {RATING_QUESTION_TYPES.includes(currentQuestion.questionType) && (
            <RatingQuestion
              response={response}
              question={currentQuestion}
              isMobile={isMobileDevice}
              handleUpdate={handleQuestionResponse}
              handleDelete={handleRemoveQuestionResponse}
              isButtonsDisabled={disableButtons}
              disableButtons={setDisableButtons}
              isLastQuestion={currentQuestionIndex === orderedMentalityAssessmentQuestions.length - 1}
            />
          )}
          {CHOOSE_MANY_QUESTION_TYPES.includes(currentQuestion.questionType) && (
            <ChooseManyQuestion
              response={response}
              question={currentQuestion}
              handleUpdate={handleChooseManyQuestionResponse}
              handleDelete={handleRemoveQuestionResponse}
              currentQuestionIndex={currentQuestionIndex}
              handleNextQuestion={setCurrentQuestionIndex}
              isLastQuestion={currentQuestionIndex === orderedMentalityAssessmentQuestions.length - 1}
              submitAssessment={submitAssessment}
            />
          )}
          {renderButtons()}
          {
            <div>
              <ToastContainer position="top-center" autoClose={submitAssessmentTimeDelay} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss pauseOnHover />
            </div>
          }
        </>
      }
    </>
  );
}

export default AssessmentQuestions;
