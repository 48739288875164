import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { setDoc } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../utils';

const completeMentalityAssessment = async (assessmentID) => {
  const docRef = AssessmentRef(assessmentID);

  try {
    await setDoc(
      docRef,
      {
        status: 'completed',
        lastModified: getCurrentTimestamp(),
        completedOn: getCurrentTimestamp()
      },
      { merge: true }
    );
  } catch (err) {
    console.log('Unable to set mentality assessment to complete', err);
  }
};

export default function useCompleteMentalityAssessment(assessmentID) {
  const CompleteMentalityAssessmentMutation = () => {
    return completeMentalityAssessment(assessmentID);
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CompleteMentalityAssessmentMutation,
    onError: (error, data, context) => {
      console.log(`error completing assessment: ${error}`);
    },
    onSuccess: () => {
      console.log(`completed assessment`);
    }
  });
}
