import React from 'react';
import logo from '../../assets/logos/Scorability-Logo-Black-RGB.jpg';

function Header() {
  const RenderLogo = () => {
    return (
      <React.Fragment>
        <img className="logo desktop" src={logo} alt="Scorability" />
        <img className="logo mobile" src={logo} alt="Scorability" />
      </React.Fragment>
    );
  };

  return (
    <div className="header-container">
      <RenderLogo />
    </div>
  );
}

export default Header;
