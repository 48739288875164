import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../utils';

const fetchMentalityAssessmentStatus = async () => {
  const callable = httpsCallable(functions, 'statusesForAssessments');

  const response = await callable();

  const assessmentStatuses = response.data.assessmentStatuses;
  // sort by status field
  return _.orderBy(assessmentStatuses, (a) => new Date(a.completedOn), ['asc']);
};

export default function useMentalityAssessmentStatus() {
  return useQuery({
    queryKey: ['assessmentStatuses'],
    queryFn: async () => fetchMentalityAssessmentStatus(),
    onError: (err) => {
      console.error('error with grabbing assessments: ', err);
    }
  });
}
