import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { setDoc, getDoc } from 'firebase/firestore';

const createMentalityAssessment = async (assessmentID, data) => {
  const docRef = AssessmentRef(assessmentID);

  // check if assessment exists
  const doc = await getDoc(docRef);

  if (doc.exists()) {
    console.log('Assessment already exists');
    // return existing assessment
    return { data: doc.data(), exists: true };
  }

  try {
    await setDoc(docRef, data, { merge: true });
    console.log('Created mentality assessment: ');
  } catch (err) {
    console.log('Unable to create mentality assessment', err);
  }

  return { data };
};

export default function useCreateMentalityAssessment(assessmentID) {
  const CreateMentalityAssessmentMutation = ({ data }) => {
    return createMentalityAssessment(assessmentID, data);
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CreateMentalityAssessmentMutation,
    onError: (error, data, context) => {
      // console.error(error, data, context);
      // queryClient.setQueryData(['mentalityAssessment', assessmentID], context.prevData);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['mentalityAssessment', assessmentID], data);
    }
  });
}
