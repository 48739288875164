import React from 'react';

function Footer() {
  // get the current year
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content">
          <p>
            © {currentYear} Scorability, Inc. All Rights Reserved.
            <br />
            <a className="contact-us" href="mailto:help@scorability.com">
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
