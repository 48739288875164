import { useMutation } from '@tanstack/react-query';
import { AssessmentActivityLogsRef } from '../../utils/refs';
import { setDoc } from 'firebase/firestore';

const updateAssessmentActivityLog = async (assessmentID, questionID, type) => {
  const docRef = AssessmentActivityLogsRef(assessmentID);

  try {
    let id = new Date().valueOf();
    if (type === 'responseSubmitted') {
      id -= 850;
    }

    await setDoc(
      docRef,
      {
        actions: {
          [`${id}`]: {
            id: questionID,
            action: type,
            timestamp: id
          }
        }
      },
      { merge: true }
    );
  } catch (err) {
    console.log('Unable to update assessment activity log', err);
  }

  return { changes: { questionID, type } };
};

export default function useUpdateAssessmentActivityLog(assessmentID) {
  const UpdateAssessmentActivityLogMutation = ({ questionID, type }) => {
    return updateAssessmentActivityLog(assessmentID, questionID, type);
  };

  return useMutation({
    mutationFn: UpdateAssessmentActivityLogMutation,
    onError: (error, data, context) => {
      console.error('Error updating assessment activity log', error, data, context);
    },
    onSuccess: ({ changes }) => {
      console.log('Successfully updated assessment activity log', changes);
    }
  });
}
