import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import LogRocket from 'logrocket';

import APP_CONFIG from '../config';

export const Firebase = initializeApp(APP_CONFIG.FIREBASE_CONFIG);

export const functions = getFunctions(Firebase);

export const db = getFirestore(Firebase);

const auth = getAuth(Firebase);

LogRocket.init(APP_CONFIG.LOGROCKET_KEY);

export function unwrapDoc(doc) {
  //don't add onto doc if not object
  if (typeof doc.data() !== 'object') {
    return doc.data();
  }

  let unwrappedDoc = {
    ...doc.data()
  };

  unwrappedDoc._id = doc.id;

  return unwrappedDoc;
}

export function unwrapDocs(docs, includeDeleted = false) {
  let unwrappedDocs = [];

  docs.forEach((doc) => {
    //don't add onto unwrappedDocs if not object
    if (typeof doc.data() !== 'object') {
      return;
    }

    let unwrappedDoc = doc.data();
    unwrappedDoc._id = doc.id;

    if (!unwrappedDoc._deleted || includeDeleted) {
      unwrappedDocs.push(unwrappedDoc);
    }
  });

  return unwrappedDocs;
}

//UTC timestamp in seconds
export function getCurrentTimestamp() {
  return new Date().valueOf();
}

export function getCurrentUserId() {
  return auth?.currentUser?.uid;
}

export function signOut() {
  return auth.signOut();
}

export function scopeLogRocketCaptureToUser(uid, userData) {
  if (userData) {
    LogRocket.identify(uid, {
      ...(userData.firstName && { name: userData.firstName + ' ' + userData.lastName }),
      ...(userData.email && { email: userData.email }),
      ...(userData.scorabilityUserId && { scorabilityUserId: userData.scorabilityUserId })
    });
  } else {
    LogRocket.identify(uid);
  }
}

export function getAppVersion() {
  return 'Version ' + APP_CONFIG.VERSION;
}

export function isProduction() {
  return APP_CONFIG.ENV === 'production';
}

export function isDevelopment() {
  return APP_CONFIG.ENV === 'development';
}

export function isLocal() {
  return APP_CONFIG.ENV === 'local';
}
