import React, { useEffect, useState } from 'react';
import { SquareCheck } from 'lucide-react';
function RatingQuestion({ response, question, isMobile, handleUpdate, handleDelete, isButtonsDisabled, disableButtons, isLastQuestion }) {
  const { id, text, scale, labelBottom, labelTop, displayIndex } = question;
  const [selectedOption, setSelectedOption] = useState(null);
  const [animationActive, setAnimationActive] = useState(false);

  useEffect(() => {
    setSelectedOption(response);
  }, [response]);

  const handleClick = (value) => {
    disableButtons(true);

    setTimeout(() => {
      handleUpdate(id, value);
      if (!isLastQuestion) {
        setSelectedOption(null);
      }
      setAnimationActive(false);
    }, 850);
  };

  // create an array called sortedOptions that is the length of the scale and starts at 1
  // e.g. if scale is 5, sortedOptions will be [1, 2, 3, 4, 5]
  // this will be used to create the rating buttons
  let sortedOptions = [...Array(scale + 1).keys()].slice(1);

  return (
    <div className="assessment-question-container">
      <div className={`question-container ${!isLastQuestion && animationActive ? 'animation' : ''}`}>
        <div className="question-number">{displayIndex}.</div>
        <div className="question-text" dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      {isMobile && question.questionType === 'rating-10' && (
        <div className="rating-helper-info">
          <div>
            {sortedOptions[0]} being the {labelBottom}
          </div>
          <div>
            {sortedOptions[scale - 1]} being the {labelTop}
          </div>
        </div>
      )}
      <div className={`question-options-container ${question.questionType}`}>
        {sortedOptions.map((option, index) => (
          <button
            key={id + index}
            disabled={animationActive || isButtonsDisabled}
            className={`button question-button ${question.questionType} ${selectedOption === option ? `selected ${animationActive ? 'animation' : ''}` : ''}`}
            onClick={(e) => {
              if (selectedOption === option) {
                setSelectedOption(null);
                handleDelete(id);
              } else {
                setAnimationActive(true);
                setSelectedOption(option);
                handleClick(option);
              }
            }}>
            {option}
            {selectedOption === option && (
              <div className={`check-mark ${question.questionType}`}>
                <SquareCheck size={18} />
              </div>
            )}
          </button>
        ))}
      </div>
      {(!isMobile || question.questionType === 'rating-5') && (
        <div className={`rating-helper-info ${question.questionType}`}>
          <div>{labelBottom}</div>
          <div>{labelTop}</div>
        </div>
      )}
    </div>
  );
}

export default RatingQuestion;
