import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useMentalityAssessment, useMentalityAssessmentQuestions, useScorabilitySlugVerificationAndCustomToken, useCreateMentalityAssessment, useUpdateAssessmentActivityLog } from '../../hooks';
import { getCurrentTimestamp, getCurrentUserId, scopeLogRocketCaptureToUser } from '../../utils';
import AssessmentQuestions from './questions';
import Loading from '../util/loading';
import Error from '../util/error';
import { getAppVersion } from '../../utils';

function Assessments() {
  const urlParams = new URLSearchParams(window.location.search);

  const paramData = {
    firstName: urlParams.get('first_name'),
    lastName: urlParams.get('last_name'),
    classYear: urlParams.get('class_year'),
    scorabilityUserId: urlParams.get('slug'),
    position: urlParams.get('position'),
    highSchoolId: urlParams.get('high_school_id'),
    collegeId: urlParams.get('college_id'),
    institutionLevel: urlParams.get('institution_level'),
    sex: urlParams.get('sex'),
    sport: urlParams.get('sport'),
    spsaId: urlParams.get('spsa_id'),
    redirectURL: urlParams.get('redirect_url')
  };

  // param validation?
  if (!paramData.scorabilityUserId || !paramData.spsaId) {
    return <Error header={'404: SORRY! We’re having trouble.'} message={`Please contact us for assistance at <a href="mailto:help@scorability.com">help@scorability.com</a>`} />;
  }

  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(false);

  const { data: customToken, isFetched: customTokenFetched } = useScorabilitySlugVerificationAndCustomToken(paramData.scorabilityUserId);
  const { mutateAsync: createMentalityAssessment } = useCreateMentalityAssessment(paramData.scorabilityUserId);
  const { mutate: updateAssessmentActivityLog } = useUpdateAssessmentActivityLog(paramData.scorabilityUserId);

  useEffect(() => {
    console.log('Current App Version: ', getAppVersion());

    if (!customTokenFetched) {
      return;
    }

    if (!customToken) {
      setPageError(true);
      setPageLoading(false);
      return;
    }
    const auth = getAuth();
    signInWithCustomToken(auth, customToken)
      .then(async (userCredential) => {
        console.log('signed in with custom token');
        scopeLogRocketCaptureToUser(userCredential.user.uid, {
          firstName: paramData.firstName,
          lastName: paramData.lastName,
          scorabilityUserId: paramData.scorabilityUserId
        });
        // returns true if assessment was created, false if it already exists
        const responseData = await createMentalityAssessment({
          data: {
            scorabilityUserData: paramData,
            userId: userCredential.user.uid,
            status: 'inprogress',
            responses: {},
            scores: {},
            startedOn: getCurrentTimestamp()
          }
        });

        if (!responseData.exists) {
          updateAssessmentActivityLog({ questionID: null, type: 'assessmentCreated' });
        }

        setPageLoading(false);
      })
      .catch((error) => {
        console.log('Could not sign in with custom token', error);
        setPageError(true);
        setPageLoading(false);
      });
  }, [customToken, customTokenFetched]);

  const { data: mentalityAssessment, isError: mentalityAssessmentError, isLoading: mentalityAssessmentLoading } = useMentalityAssessment(getCurrentUserId());
  const {
    data: orderedMentalityAssessmentQuestions,
    isError: orderedMentalityAssessmentQuestionsError,
    isLoading: mentalityAssessmentQuestionsLoading
  } = useMentalityAssessmentQuestions('1.0', getCurrentUserId());

  if (pageLoading || mentalityAssessmentLoading || mentalityAssessmentQuestionsLoading) {
    return <Loading />;
  } else if (pageError) {
    return <Error header={'404: SORRY! We’re having trouble.'} message={`Please contact us for assistance at <a href="mailto:help@scorability.com">help@scorability.com</a>`} />;
  } else if (mentalityAssessmentError || orderedMentalityAssessmentQuestionsError) {
    return (
      <Error header={'404: SORRY! We’re having trouble finding your assessment.'} message={`Please contact us for assistance at <a href="mailto:help@scorability.com">help@scorability.com</a>`} />
    );
  }

  return (
    <div className="content-wrapper">
      <div className="assessment-container">
        <div className="assessment-content">
          {!mentalityAssessmentLoading && !mentalityAssessmentQuestionsLoading && (
            <AssessmentQuestions
              mentalityAssessment={mentalityAssessment}
              orderedMentalityAssessmentQuestions={orderedMentalityAssessmentQuestions}
              scorabilityUserId={paramData.scorabilityUserId}
              redirectURL={paramData.redirectURL}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Assessments;
