const APP_VERSION_NUMBER = '1.0.5';

const APP_ENV = process.env.REACT_APP_ENV || 'DEV';

const APP_CONFIG = {
  DEV: {
    ENV: 'development',
    VERSION: APP_VERSION_NUMBER,
    ADVANCED_DEBUG: false,
    LOGROCKET_KEY: '4utekz/scorability-dev',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyAna_JQKvSmafOHdLxyd5WmaZrbfjWt5Mc',
      authDomain: 'scorability-app-dev.firebaseapp.com',
      projectId: 'scorability-app-dev',
      storageBucket: 'scorability-app-dev.appspot.com',
      messagingSenderId: '500522816829',
      appId: '1:500522816829:web:83d55155aa1705ff806154',
      measurementId: 'G-VCXEPMRT15'
    }
  },
  PROD: {
    ENV: 'production',
    VERSION: APP_VERSION_NUMBER,
    ADVANCED_DEBUG: false,
    LOGROCKET_KEY: '4utekz/scorability-app',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyBOrnF7s7ZczvMb0bi5rZrpzsSw8egNon0',
      authDomain: 'scorability-app.firebaseapp.com',
      projectId: 'scorability-app',
      storageBucket: 'scorability-app.appspot.com',
      messagingSenderId: '570737605440',
      appId: '1:570737605440:web:eb7719caaa0f35ced1d8a5',
      measurementId: 'G-22TQZNHJ7F'
    }
  }
};

export default APP_CONFIG[APP_ENV];

export const ALL_CONFIGS = APP_CONFIG;
