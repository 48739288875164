import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { setDoc } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../utils';

const updateMentalityAssessment = async (assessmentID, questionID, changes) => {
  const docRef = AssessmentRef(assessmentID);

  try {
    await setDoc(
      docRef,
      {
        responses: {
          [`${questionID}`]: changes
        },
        lastModified: getCurrentTimestamp()
      },
      { merge: true }
    );
  } catch (err) {
    console.log('Unable to update mentality assessment responses', err);
  }

  return { changes };
};

export default function useUpdateMentalityAssessment(assessmentID) {
  const UpdateMentalityAssessmentMutation = ({ questionID, changes }) => {
    return updateMentalityAssessment(assessmentID, questionID, changes);
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: UpdateMentalityAssessmentMutation,
    onError: (error, data, context) => {
      queryClient.setQueryData(['mentalityAssessment', assessmentID], context.prevData);
    },
    onSuccess: ({ changes }) => {
      queryClient.invalidateQueries({ queryKey: ['mentalityAssessment'] });
    }
  });
}
