import React from 'react';
import Table from '../table';
import _ from 'lodash';
import { useMentalityAssessmentStatus } from '../../hooks';

function AssessmentStatus() {
  const { data: assessmentStatuses, isLoading } = useMentalityAssessmentStatus();

  return (
    <div className="content-wrapper">
      <div className="status-container">
        <div className="status-title">Assessment Statuses</div>
        <div className="status-list">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Table
              columns={[
                {
                  accessorKey: 'scorabilityId',
                  header: 'Slug',
                  cell: ({ row, getValue }) => <div className="table-cell">{getValue() + '*******'}</div>
                },
                {
                  accessorKey: 'status',
                  header: 'Status',
                  cell: ({ row, getValue }) => <div className="table-cell">{getValue()}</div>
                },
                {
                  accessorKey: 'startedOn',
                  header: 'Started On',
                  cell: ({ row, getValue }) => <div className="table-cell">{new Date(getValue()).toLocaleString()}</div>
                },
                {
                  accessorKey: 'completedOn',
                  header: 'Completed On',
                  cell: ({ row, getValue }) => <div className="table-cell">{getValue() ? new Date(getValue()).toLocaleString() : ''}</div>
                }
              ]}
              data={assessmentStatuses}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AssessmentStatus;
