import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

function Loading() {
  const [text, setText] = useState('Loading assessment...');

  setTimeout(() => {
    setText('Almost there...');
  }, 10000);

  return (
    <div className="loading-container">
      <div className="loading-content">
        <ClipLoader color={'blue'} size={20} aria-label="Loading Spinner" data-testid="loader" />
        <div className="loading-text">{text}.</div>
      </div>
    </div>
  );
}

export default Loading;
