import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { deleteField, setDoc } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../utils';

const removeMentalityAssessmentResponse = async (assessmentID, questionID) => {
  const docRef = AssessmentRef(assessmentID);

  try {
    await setDoc(
      docRef,
      {
        responses: {
          [questionID]: null
        },
        lastModified: getCurrentTimestamp()
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.log('Unable to remove mentality assessment response', err);
    return false;
  }
};

export default function useRemoveMentalityAssessmentResponse(assessmentID) {
  const RemoveMentalityAssessmentResponse = ({ questionID }) => {
    return removeMentalityAssessmentResponse(assessmentID, questionID);
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: RemoveMentalityAssessmentResponse,
    onError: (error, data, context) => {
      queryClient.setQueryData(['mentalityAssessment', assessmentID], context.prevData);
    },
    onSuccess: ({ changes }) => {
      queryClient.invalidateQueries({ queryKey: ['mentalityAssessment'] });
    }
  });
}
