import { useQuery } from '@tanstack/react-query';
import { AssessmentVersionsRef } from '../../utils/refs';
import { unwrapDoc } from '../../utils';
import { getDoc } from 'firebase/firestore';
import _ from 'lodash';

const fetchMentalityAssessmentQuestions = async (assessmentVersionId) => {
  const assessmentVersionDoc = await getDoc(AssessmentVersionsRef(assessmentVersionId));
  const assessmentVersion = unwrapDoc(assessmentVersionDoc);

  const SECTION_ORDER = assessmentVersion.sectionOrder;
  const assessmentQuestions = assessmentVersion.questions;

  // for each section, order the questions by questionIndex
  const orderedQuestionsBySection = _.mapValues(assessmentQuestions, (questions) => _.sortBy(questions, 'orderBy'));

  // order questions by section order
  const orderedQuestions = _.flatMap(SECTION_ORDER, (group) => orderedQuestionsBySection[group]);

  // add rank scale to each rank question if it is rating-10 or rating-5
  const orderedQuestionsWithRankScale = orderedQuestions.map((question) => {
    if (question.questionType === 'rating-10') {
      return { ...question, scale: 10 };
    }

    if (question.questionType === 'rating-5') {
      return { ...question, scale: 5 };
    }

    return question;
  });

  let displayIndex = 1;
  const orderedQuestionsWithDisplayIndex = orderedQuestionsWithRankScale.map((question) => {
    if (question.questionType === 'statement') {
      return question;
    }
    const questionWithDisplayIndex = { ...question, displayIndex };
    displayIndex++;
    return questionWithDisplayIndex;
  });

  return orderedQuestionsWithDisplayIndex;
};

export default function useMentalityAssessmentQuestions(assessmentVersionId, userId) {
  return useQuery({
    queryKey: ['mentalityAssessmentQuestions', assessmentVersionId],
    queryFn: async () => fetchMentalityAssessmentQuestions(assessmentVersionId),
    enabled: !!assessmentVersionId && !!userId
  });
}
