import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AssessmentRef } from '../../utils/refs';
import { setDoc } from 'firebase/firestore';
import { getCurrentTimestamp } from '../../utils';

const bulkUpdateMentalityAssessment = async (assessmentID, changes) => {
  const docRef = AssessmentRef(assessmentID);

  try {
    await setDoc(
      docRef,
      {
        responses: changes,
        lastModified: getCurrentTimestamp()
      },
      { merge: true }
    );
  } catch (err) {
    console.log('Unable to bulk update mentality assessment responses', err);
  }

  return { changes };
};

export default function useBulkUpdateMentalityAssessment(assessmentID) {
  const BulkUpdateMentalityAssessmentMutation = ({ changes }) => {
    return bulkUpdateMentalityAssessment(assessmentID, changes);
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: BulkUpdateMentalityAssessmentMutation,
    onError: (error, data, context) => {
      // console.error(`error updating assessment: ${error}`, data, context);
      queryClient.setQueryData(['mentalityAssessment', assessmentID], context.prevData);
    },
    onSuccess: ({ changes }) => {
      queryClient.invalidateQueries({ queryKey: ['mentalityAssessment'] });
    }
  });
}
