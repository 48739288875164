import React, { useEffect } from 'react';
import { useUpdateAssessmentActivityLog } from '../../hooks';

// say that the user is unauthorized to view the page
function Error({ header, message, buttonUrl, scorabilityUserId, isCompleted }) {
  const { mutate: updateAssessmentActivityLog } = useUpdateAssessmentActivityLog(scorabilityUserId);
  console.log(buttonUrl);
  useEffect(() => {
    if (isCompleted) {
      updateAssessmentActivityLog({ questionID: null, type: 'User On Completed Page' });
    }
  }, []);

  return (
    <div className="error-container">
      <h1>{header}</h1>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      {buttonUrl && (
        <button className="button primary" onClick={() => (window.location.href = buttonUrl)}>
          Go to Dashboard
        </button>
      )}
    </div>
  );
}

export default Error;
