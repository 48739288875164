import React, { useEffect, useState } from 'react';
import { SquareCheck } from 'lucide-react';

function ChoiceQuestion({ response, question, handleUpdate, handleDelete, isButtonsDisabled, disableButtons, isLastQuestion }) {
  const { id, text, options, displayIndex, questionType } = question;
  const [selectedOption, setSelectedOption] = useState(null);
  const [animationActive, setAnimationActive] = useState(false);
  const [sortedOptions, setSortedOptions] = useState(options);

  const reverseQuestionTypes = ['likert'];

  useEffect(() => {
    setSelectedOption(response);
  }, [response]);

  const handleClick = (value) => {
    disableButtons(true);

    setTimeout(() => {
      handleUpdate(id, value);
      if (!isLastQuestion) {
        setSelectedOption(null);
      }
      setAnimationActive(false);
    }, 850);
  };

  useEffect(() => {
    function handleOptionOrder() {
      if (reverseQuestionTypes.includes(questionType) && window.innerWidth > 800) {
        setSortedOptions([...options].reverse());
      } else if (questionType === 'boolean' && window.innerWidth < 800) {
        setSortedOptions([...options].reverse());
      } else {
        setSortedOptions(options);
      }
    }

    handleOptionOrder();

    window.addEventListener('resize', handleOptionOrder);

    return () => {
      window.removeEventListener('resize', handleOptionOrder);
    };
  }, [window.innerWidth, question]);

  return (
    <div className="assessment-question-container">
      <div className={`question-container ${!isLastQuestion && animationActive ? 'animation' : ''}`}>
        <div className="question-number">{displayIndex}.</div>
        <div className="question-text" dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <div className={`question-options-container choices`}>
        {sortedOptions.map((option, index) => (
          <button
            key={id + index}
            disabled={animationActive || isButtonsDisabled}
            className={`button question-button choice ${selectedOption === option.id ? `selected ${animationActive ? 'animation' : ''}` : ''} `}
            onClick={(e) => {
              // if the option is already selected, unselect it
              if (selectedOption === option.id) {
                setSelectedOption(null);
                handleDelete(id);
                return;
              } else {
                setAnimationActive(true);
                setSelectedOption(option.id);
                handleClick(option.id);
              }
            }}>
            {option.label}
            {selectedOption === option.id && (
              <div className="check-mark">
                <SquareCheck size={18} />
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ChoiceQuestion;
