const MOCK_RESPONSES = {
  miixmn: 'a',
  jfrfey: 'a',
  w1mjbk: 'a',
  a2ikuo: 5,
  iw8wpi: 'a',
  zkbie7: 5,
  a7h7m8: 5,
  japma4: 5,
  b30q2x: 5,
  y3k8f9: 5,
  d0r111: 'a',
  m7x4ke: 'a',
  shjsci: 5,
  tr3mmk: 'a',
  dnoa2w: 5,
  gwrdqk: 'a',
  ljmuon: 5,
  cs0umq: 5,
  lca6k6: 5,
  nauuex: 5,
  s4409d: 'a',
  bmljsr: 'a',
  ith75s: 'a',
  xw7gem: 5,
  ccvt2d: 5,
  du965r: 'a',
  xjqm7l: 'a',
  ufwdvh: 'a',
  xe8hqr: 5,
  fso0rj: 'a',
  iivbuz: 'a',
  pwuieh: 'a',
  yf39d8: 5,
  yaoug4: 'a',
  swlopg: 'a',
  xccl8b: 5,
  gutu5n: 'a',
  x8p0hv: 5,
  gxwp5w: 5,
  l5muo7: 'a',
  sn4yrm: 'a',
  pg767n: 'a',
  xtjdvk: 'a',
  ah6ort: 'a',
  x2x2mi: 5,
  sbz9k0: 5,
  k6ll0j: 'a',
  xbqc14: 5,
  mk262m: 5,
  pzny5l: 'a',
  po14k9: 'a',
  icm3ne: 'a',
  rpagu7: 5,
  hp47ek: 'a',
  wcrzko: 'a',
  h9zy4t: 'a',
  rcmjs2: 'a',
  j500iw: 'a',
  z2v25v: 'a',
  hgl8ed: 'a',
  nq1mbc: 'a',
  cb447u: 'a',
  skw9mn: 'a',
  fu8dkt: 'a',
  p2abeq: 'a',
  v26zho: 'a',
  jim5kt: 'a',
  wlv49s: 'a',
  p54pj0: 'a'
};

//  i2bn61: 'a',

export default MOCK_RESPONSES;
