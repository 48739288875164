import React from 'react';
import { isProduction } from '../../../utils';

function Statement({ text, subtext, showBackButton, handleAction }) {
  return (
    <div className="statement-container">
      <div className="statement-text" dangerouslySetInnerHTML={{ __html: text }}></div>
      <div className="statement-subtext">{subtext}</div>
      <div className="statement-button-container">
        {showBackButton && (
          <button className="button grey-outline" onClick={() => handleAction(-1)}>
            Back
          </button>
        )}
        {/* {isProduction() && !showBackButton && (
          <button className="button grey-outline" onClick={() => handleAction(0, true)}>
            Prefill Questions
          </button>
        )} */}
        <div className="statement-divider"></div>
        <button className="button primary" onClick={() => handleAction(1)}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default Statement;
