import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../utils';

const fetchScorabilitySlugVerificationAndCustomToken = async (slug) => {
  const callable = httpsCallable(functions, 'authenticateSlugFromScorabilityAndGetCustomToken');

  const response = await callable({
    slug
  });

  return response.data.customToken;
};

export default function useScorabilitySlugVerificationAndCustomToken(slug) {
  return useQuery({
    enabled: !!slug,
    queryKey: ['SlugVerification', slug],
    queryFn: async () => fetchScorabilitySlugVerificationAndCustomToken(slug),
    onError: (err) => {
      console.error('error with validating or creating custom token: ', err);
    }
  });
}
